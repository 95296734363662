import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import HomePage from '@/components/domains/home/HomePage.vue';
import LoginPage from '@/components/domains/account/login/LoginPage.vue';
import MyPage from "@/components/domains/account/mypage/MyPage.vue";
import EstheCalendarPage from "@/components/domains/calender/EstheCalendarPage.vue";
import CalteManagement from "@/components/domains/calte/pic/CalteManagement.vue";
import FilloutCalte from "@/components/domains/calte/customer/FilloutCalte.vue";
import CalteList from "@/components/domains/calte/pic/CalteList.vue";
import CustomerRegisterPage from "@/components/domains/account/register/CustomerResigterPage.vue";
import PICResigterPage from "@/components/domains/account/register/PICResigterPage.vue";
import AIEsthePlanSelectionPage from "@/components/domains/esthe/aiesthe/AIEsthePlanSelectionPage.vue";
import AIEstheDetectFacePlanPage
    from "@/components/domains/esthe/aiesthe/plans/detectface/AIEstheDetectFacePlanPage.vue";
import AIEstheFeelingPlanPage from "@/components/domains/esthe/aiesthe/plans/feeling/AIEstheFeelingPlanPage.vue";
import AIEstheRandomPlanPage from "@/components/domains/esthe/aiesthe/plans/AIEstheRandomPlanPage.vue";
import AdminRegisterPage from "@/components/domains/account/register/AdminRegisterPage.vue";
import EasilyLoginPage from "@/components/domains/account/login/EasilyLoginPage.vue";
import Settings from "@/components/domains/settings/Settings.vue";
import EasilyLoginAgreement from "@/components/domains/settings/easilylogin/EasilyLoginAgreement.vue";
import EasilyLoginIssueId from "@/components/domains/settings/easilylogin/EasilyLoginIssueURL.vue";
import EasilyLoginSetting from "@/components/domains/settings/EasilyLoginSetting.vue";
import Authorization from "@/components/domains/authorization/Authorization.vue";
import FreeStressCheckPage from "@/components/domains/mentalcheck/FreeStressCheckPage.vue";
import GrantAuthority from "@/components/domains/authorization/GrantAuthority.vue";
import ApplyingList from "@/components/domains/authorization/ApplyingList.vue";
import ApprovalList from "@/components/domains/authorization/ApprovalList.vue";
import ManagementEstheCalendar from "@/components/domains/calender/ManagementEstheCalendarPage.vue";
import AccountTransfer from "@/components/domains/account/transfer/AccountTransfer.vue";
import PlaygroundPage from "@/components/domains/PlaygroundPage.vue";
import PageSizeSetting from "@/components/domains/settings/pagesize/PageSizeSetting.vue";
import CustomerAccountTransfer from "@/components/domains/account/transfer/customer/CustomerAccountTransfer.vue";
import SoundEstheRandomPlan from "@/components/domains/esthe/soundesthe/plans/SoundEstheRandomPlan.vue";
import SoundEsthePlanSelectionPage from "@/components/domains/esthe/soundesthe/SoundEsthePlanSelectionPage.vue";
import ChangingPassword from "@/components/domains/settings/password/ChangingPassword.vue";
import GeneralRegisterPage from "@/components/domains/account/register/GeneralRegisterPage.vue";
import ProfilePage from "@/components/domains/account/profile/ProfilePage.vue";
import ActivityPage from "@/components/domains/activity/ActivityPage.vue";
import MentalCheck from "@/components/domains/mentalcheck/MentalCheck.vue";
import MentalCheckSelection from "@/components/domains/mentalcheck/MentalCheckSelection.vue";
import AIEstheHistory from "@/components/domains/esthe/aiesthe/history/AIEstheHistory.vue";
import AIEstheDetectFaceHistory from "@/components/domains/esthe/aiesthe/history/AIEstheDetectFaceHistory.vue";
import AIEstheComparingHistory from "@/components/domains/esthe/aiesthe/history/AIEstheComparingHistory.vue";
import DMVUpload from "@/components/domains/upload/dmvupload/DMVUpload.vue";
import Upload from "@/components/domains/upload/Upload.vue";
import SoundUpload from "@/components/domains/upload/soundupload/SoundUpload.vue";
import SubscriptionState from "@/components/domains/payment/status/SubscriptionState.vue";
import PaymentHistoryPage from "@/components/domains/payment/histroy/PaymentHistoryPage.vue";
import PaymentMethod from "@/components/domains/settings/payment/PaymentMethod.vue";
import KanadeUpload from "@/components/domains/upload/kanadeupload/KanadeUpload.vue";
import KanadePlanSelection from "@/components/domains/esthe/soundesthe/kanade/KanadePlanSelection.vue";
import KanadeRandomPlan from "@/components/domains/esthe/soundesthe/kanade/courses/KanadeRandomPlan.vue";
import ForgotPasswordPage from "@/components/domains/account/forgot_password/ForgotPasswordPage.vue";
import SoundEstheCategoryPlan from "@/components/domains/esthe/soundesthe/plans/SoundEstheCategoryPlan.vue";
import SoundEsthePurposePlan from "@/components/domains/esthe/soundesthe/plans/SoundEsthePurposePlan.vue";
import SoundEsthePurposeDetail from "@/components/domains/esthe/soundesthe/plans/purposes/SoundEsthePurposeDetail.vue";
import SoundEsthePurposePlaying
    from "@/components/domains/esthe/soundesthe/plans/purposes/SoundEsthePurposePlaying.vue";
import PicManagementPage from "@/components/domains/management/pic/PicManagementPage.vue";
import MenuPage from "@/components/domains/home/MenuPage.vue";
import PremiumPlansPage from "@/components/domains/products/PremiumPlansPage.vue";
import PaymentPage from "@/components/domains/payment/PaymentPage.vue";
import DrAiAndHealthCarePlanPage
    from "@/components/domains/esthe/aiesthe/plans/dr_ai_healthcare/DrAiAndHealthCarePlanPage.vue";
import Under64Page from "@/components/domains/esthe/aiesthe/plans/dr_ai_healthcare/Under64Page.vue";
import Up65Page from "@/components/domains/esthe/aiesthe/plans/dr_ai_healthcare/Up65Page.vue";
import SupportPage from "@/components/domains/support/SupportPage.vue";
import SoundTherapyDMVUpload from "@/components/domains/upload/sound_therapy/SoundTherapyDMVUpload.vue";
import AIEstheSoundTherapyPage
    from "@/components/domains/esthe/aiesthe/plans/sound_therapy/AIEstheSoundTherapyPage.vue";
import PrivacyPolicyPage from "@/components/domains/agreement/PrivacyPolicyPage.vue";
import PaymentDetailPage from "@/components/domains/payment/detail/PaymentDetailPage.vue";
import PaymentMethodPage from "@/components/domains/payment/method/PaymentMethodPage.vue";
import PremiumPlanAccountMethodPage from "@/components/domains/products/PremiumPlanAccountSelectPage.vue";
import EnvironmentPage from "@/components/domains/home/EnvironmentPage.vue";
import ChatSupportPage from "@/components/domains/support/ChatSupportPage.vue";
import EnterpriseUpload from "@/components/domains/upload/enterprise_upload/EnterpriseUpload.vue";
import EnterprisePlanSelectionPage from "@/components/domains/enterprise/EnterprisePlanSelectionPage.vue";
import EnterpriseExperiencePlanPage from "@/components/domains/enterprise/plans/EnterpriseRandomPlanPage.vue";
import EnterpriseControlPlanPage from "@/components/domains/enterprise/plans/control/EnterpriseControlPlanPage.vue";
import EnterpriseControlPlayingPage
    from "@/components/domains/enterprise/plans/control/EnterpriseControlPlayingPage.vue";
import BonusCodeForm from "@/components/domains/settings/bonuscode/BonusCodeForm.vue";
import AdvertisePage from "@/components/domains/advertise/AdvertisePage.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    // For Test
    {
        path: '/playground',
        name: "Playground",
        component: PlaygroundPage
    },
    {
        path: '/',
        name: 'Home',
        component: HomePage
    },
    {
        path: '/support',
        name: 'Support',
        component: SupportPage
    },
    {
        path: '/support/chat',
        name: 'ChatSupport',
        component: ChatSupportPage,
    },
    {
        path: '/environment',
        name: 'Environment',
        component: EnvironmentPage
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicyPage
    },
    {
        path: '/stress-check',
        name: 'StressCheck',
        component: FreeStressCheckPage
    },
    {
        path: '/easily-login/:path',
        name: 'EasilyLogin',
        component: EasilyLoginPage
    },
    // 一般ユーザー向け
    {
        path: '/register/:path',
        name: 'GeneralRegister',
        component: GeneralRegisterPage
    },
    {
        path: '/register-personal/:path',
        name: 'GeneralRegister',
        component: GeneralRegisterPage
    },
    {
        path: '/register-personal',
        name: 'GeneralRegister',
        component: GeneralRegisterPage
    },
    // 企業様・サロン様向け
    {
        path: '/register-enterprise/:agency',
        name: 'CustomerRegister',
        component: CustomerRegisterPage
    },
    {
        path: '/register-enterprise',
        name: 'CustomerRegister',
        component: CustomerRegisterPage
    },
    {
        path: '/pic-register',
        name: 'PICRegister',
        component: PICResigterPage
    },
    {
        path: '/admin-register',
        name: 'AdminRegister',
        component: AdminRegisterPage
    },
    // パスワードリセット
    {
        path: '/forgot-password',
        name: 'ForgotPasswordPage',
        component: ForgotPasswordPage
    },
    {
        path: '/forgot-password/:path',
        name: 'ForgotPasswordPage',
        component: ForgotPasswordPage
    },
    // 商品選択画面
    {
        path: '/premium-plans',
        name: 'PremiumPlans',
        component: PremiumPlansPage
    },
    {
        path: '/premium-plan-account-method',
        name: 'PremiumPlanAccountMethod',
        component: PremiumPlanAccountMethodPage
    },
    {
        path: '/payment',
        name: 'Payment',
        component: PaymentPage
    },
    // Logged In Pages
    {
        path: '/mypage',
        name: 'MyPage',
        component: MyPage,
        children: [
            // メンタルチェック
            {
                path: 'mentalcheck',
                name: "MentalCheckSelection",
                component: MentalCheckSelection
            },
            {
                path: 'mentalcheck-form/:id',
                name: "MentalCheck",
                component: MentalCheck
            },
            // カレンダー
            {
                path: 'calendar',
                component: EstheCalendarPage
            },
            // SoundEsthe
            {
                path: 'soundesthe',
                component: SoundEsthePlanSelectionPage,
            },
            {
                path: 'soundesthe/random',
                component: SoundEstheRandomPlan
            },
            {
                path: 'soundesthe/purpose',
                component: SoundEsthePurposePlan
            },
            {
                path: 'soundesthe/purpose/:code',
                component: SoundEsthePurposeDetail
            },
            {
                path: 'soundesthe/purpose/detail/:id',
                component: SoundEsthePurposePlaying
            },
            {
                path: 'soundesthe/category',
                component: SoundEstheCategoryPlan
            },
            // Kanade
            {
                path: 'kanade',
                component: KanadePlanSelection
            },
            {
                path: 'kanade/random',
                component: KanadeRandomPlan
            },
            // AIEsthe
            {
                path: 'aiesthe',
                component: AIEsthePlanSelectionPage,
            },
            {
                path: 'aiesthe/dr_ai_healthcare',
                component: DrAiAndHealthCarePlanPage,
            },
            {
                path: 'aiesthe/dr_ai_healthcare/un64',
                component: Under64Page
            },
            {
                path: 'aiesthe/dr_ai_healthcare/up65',
                component: Up65Page
            },
            {
                path: 'aiesthe/detectface',
                component: AIEstheDetectFacePlanPage
            },
            {
                path: 'aiesthe/feeling',
                component: AIEstheFeelingPlanPage
            },
            {
                path: 'aiesthe/sound_therapy',
                component: AIEstheSoundTherapyPage
            },
            {
                path: 'aiesthe/random',
                component: AIEstheRandomPlanPage
            },
            // AIEsthe 顔診断履歴
            {
                path: 'aiesthe-history',
                component: AIEstheHistory
            },
            {
                path: 'aiesthe-history/timeline',
                component: AIEstheDetectFaceHistory
            },
            {
                path: 'aiesthe-history/comparing',
                component: AIEstheComparingHistory
            },
            // Enterprise
            {
                path: 'enterprise',
                component: EnterprisePlanSelectionPage
            },
            {
                path: 'enterprise/control',
                component: EnterpriseControlPlanPage
            },
            {
                path: 'enterprise/control/:sectionId',
                component: EnterpriseControlPlayingPage
            },
            {
                path: 'enterprise/experience',
                component: EnterpriseExperiencePlanPage
            },
            // カルテ
            {
                path: 'fillout-calte',
                component: FilloutCalte
            },
            // 担当者専用ページ
            {
                path: 'calte-management',
                component: CalteManagement
            },
            {
                path: 'calte-list',
                component: CalteList
            },
            {
                path: 'mng-calendar',
                component: ManagementEstheCalendar
            },
            // 権限
            {
                path: 'authorization',
                component: Authorization
            },
            {
                path: 'applying',
                component: ApplyingList
            },
            {
                path: 'approval',
                component: ApprovalList
            },
            {
                path: 'grant-authority',
                component: GrantAuthority
            },
            // 移管
            {
                path: 'transfer',
                component: AccountTransfer
            },
            {
                path: 'transfer/customer',
                component: CustomerAccountTransfer
            },
            // 購入
            {
                path: 'payment-method',
                component: PaymentMethodPage
            },
            {
                path: 'subscription-state',
                component: SubscriptionState
            },
            {
                path: 'payment-history',
                component: PaymentHistoryPage
            },
            {
                path: 'payment-detail/:id',
                component: PaymentDetailPage
            },
            // プロフィール
            {
                path: 'profile',
                component: ProfilePage,
                name: 'Profile'
            },
            // 利用状況
            {
                path: 'activity',
                component: ActivityPage,
                name: 'Activity'
            },
            // 設定
            {
                path: 'settings',
                component: Settings,
                name: 'Settings'
            },
            // ページサイズ設定
            {
                path: 'settings/page-size',
                component: PageSizeSetting,
                name: 'PageSizeSetting'
            },
            // パスワード設定
            {
                path: 'settings/change-password',
                component: ChangingPassword,
                name: 'ChangingPassword'
            },
            // 簡易ログイン設定
            {
                path: 'settings/easily-login',
                component: EasilyLoginSetting,
                children: [
                    {
                        path: 'agreement',
                        component: EasilyLoginAgreement
                    },
                    {
                        path: 'issue-url',
                        component: EasilyLoginIssueId
                    }
                ]
            },
            // 特典コード
            {
                path: 'settings/bonuscode',
                component: BonusCodeForm,
                name: 'BonusCode'
            },
            // 支払い方法設定
            // TODO 商品リンク
            {
                path: 'settings/payment-method',
                component: PaymentMethod
            },
            // アップロード
            {
                path: 'upload',
                component: Upload
            },
            // DMV アップロード
            {
                path: 'upload/dmv',
                component: DMVUpload
            },
            // 音楽療法 アップロード
            {
                path: 'upload/sound_therapy',
                component: SoundTherapyDMVUpload
            },
            // 自然の響き アップロード
            {
                path: 'upload/sound',
                component: SoundUpload
            },
            // 奏 アップロード
            {
                path: 'upload/kanade',
                component: KanadeUpload
            },
            // 企業・施設向け アップロード
            {
                path: 'upload/enterprise',
                component: EnterpriseUpload
            }
        ]
    },

    // MENU
    {
        path: '/menu',
        name: 'Menu',
        component: MenuPage
    },
    // ADVERTISE
    {
        path: '/advertise',
        name: 'Advertise',
        component: AdvertisePage
    },
    // TEST
    {
        path: '/pic-management',
        name: "PicManagementPage",
        component: PicManagementPage
    },
];


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
